import { CurrencyPipe } from '@angular/common';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NgForm } from '@angular/forms';
import { FormUtilsService } from '@core/form-utils/form-utils.service';
import { _filter, _find, _findIndex, _isEmpty, _isNil, _map } from '@core/lodash/lodash';
import { PatientService } from '@core/patient/patient.service';
import { LogMethodTime } from '@core/performance/log-method-time.decorator';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { SECURITY_CONSTANTS } from '@core/security/security.constants';
import { InvoiceItemAdjustmentResponse } from '@gandalf/model/invoice-item-adjustment-response';
import { InvoiceItemResponse } from '@gandalf/model/invoice-item-response';
import { InvoiceResponse } from '@gandalf/model/invoice-response';
import { TransferInvoiceItemsRequest } from '@gandalf/model/transfer-invoice-items-request';
import { TransferItemRequest } from '@gandalf-black/model/transfer-item-request';
import {
	InvoiceItemAdjustmentStatus,
	InvoiceItemAdjustmentType,
	InvoiceItemStatus,
	InvoiceItemType,
	PayerType,
	PreferenceName,
	TransferType
} from '@gandalf/constants';
import { PatientNameResponse } from '@gandalf/model/patient-name-response';
import { TooltipCellRendererComponent } from '@shared/component/tooltip-cell-renderer/tooltip-cell-renderer.component';
import { assertTrue } from '@shared/validators/assert-true.validation';
import { conditionallyRequiredValidator } from '@shared/validators/conditionally-required-validation';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { AgGridAngular } from 'ag-grid-angular';
import {
	CellFocusedEvent,
	CellMouseDownEvent,
	ColDef,
	Column,
	ColumnApi,
	GridApi,
	GridOptions,
	GridReadyEvent,
	IRowNode
} from 'ag-grid-community';
import { GandalfFormArray, GandalfFormBuilder } from 'gandalf';
import {
	DynamicModalRef,
	EnumUtil,
	GridUtil,
	GridUtilService,
	ModalConfig,
	ModalManagerService,
	OptionItem,
	SortingService
} from 'morgana';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
	AccountingService,
	FormattedInsuranceResponse,
	FormattedInvoiceDateResponse,
	FormattedInvoiceDetailsItem
} from '../../core/accounting/accounting.service';
import { InvoiceItemPricingUtil } from '../../core/accounting/invoice-item-pricing/invoice-item-pricing-util';
import { InvoiceService } from '../../core/accounting/invoice-service/invoice.service';

export interface TransferItem {
	invoiceItem: InvoiceItemResponse;
	invoiceItemDetails: FormattedInvoiceDetailsItem;
	subtotal: number;
	totalDiscount: number;
	tax: number;
	balance: number;
	extPrice: number;
	adjustmentTotal: number;
	transferAmount: number;
}

@Component({
	selector: 'pms-transfer-items-modal',
	templateUrl: './transfer-items-modal.component.html',
	styles: [],
	providers: [ModalManagerService],
})
export class TransferItemsModalComponent implements OnInit, OnDestroy {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('templateForm')
	templateForm: NgForm;

	@ViewChild('agGrid')
	agGrid: AgGridAngular;

	@ViewChild('transferWriteOffGrid')
	transferWriteOffGrid: AgGridAngular;

	@ViewChild('adjustmentTooltip')
	adjustmentTooltip: TemplateRef<any>;

	@ViewChild('totalDiscountTooltip')
	totalDiscountTooltip: TemplateRef<any>;

	@ViewChild('taxTooltip')
	taxTooltip: TemplateRef<any>;

	@ViewChild('invoiceDropdown')
	invoiceDropdown: DropDownListComponent;

	@ViewChild('transferColumn')
	transferColumn: TemplateRef<any>;

	@ViewChild('adjustmentsColumn')
	adjustmentsColumn: TemplateRef<any>;

	@ViewChild('paymentTotalColumn')
	paymentTotalColumn: TemplateRef<any>;

	@ViewChild('balanceColumn')
	balanceColumn: TemplateRef<any>;

	@ViewChild('transferNumberColumn')
	transferNumberColumn: TemplateRef<any>;

	@ViewChild('transferTypeColumn')
	transferTypeColumn: TemplateRef<any>;

	@ViewChild('transferReasonColumn')
	transferReasonColumn: TemplateRef<any>;

	@ViewChild('transferToColumn')
	transferToColumn: TemplateRef<any>;

	@ViewChild('transferRemoveColumn')
	transferRemoveColumn: TemplateRef<any>;

	private unsubscribe$ = new Subject<void>();
	invoiceId: number;
	invoice: InvoiceResponse;
	request: TransferInvoiceItemsRequest;
	formGroup: UntypedFormGroup;
	transferReasons: OptionItem[];
	writeoffReasons: OptionItem[];
	transferTypes = TransferType.VALUES.values;
	patientName: PatientNameResponse;
	transferType = TransferType;
	hasFinanceCharge = false;
	payerType = PayerType;
	removeTransferToPatientOption = false;
	personInsuranceList: FormattedInsuranceResponse[];
	removeTransferToInsuranceOption = false;
	transferToInvoiceList: FormattedInvoiceDateResponse[];
	removeTransferToExistingInvoiceOption = false;
	TRANSFER_TO_NEW_INVOICE_LABEL = 'new';
	TRANSFER_TO_EXISTING_INVOICE_LABEL = 'existing';
	hideTransferToSection = false;
	payerTypes = [PayerType.PATIENT, PayerType.INSURANCE];
	showWriteOffOption = false;
	isSearching = true;
	invoiceItems: TransferItem[];
	totalCurrentBalance: string;
	totalAmountTransferred: string;
	totalBalanceAfterTransfer: string;
	transferItemColumns: ColDef[];
	transferItemGridApi: GridApi;
	transferItemGridColumnApi: ColumnApi;
	transferItemsGridOptions: GridOptions = GridUtil.buildGridOptions();
	transferAmountSum = 0;
	adjustmentTotalSum = 0;
	paymentTotalAmountSum = 0;
	balanceSum = 0;
	cancelFocusEvent = false;
	lastFocusedCell: any;
	focusedRowId: number;
	focusedColumnKey = 'transferAmount';

	get showTransferReasonsDropdown(): boolean {
		return !this.hideTransferToSection && EnumUtil.equals(this.formGroup.get('transferType').value, TransferType.TRANSFER) && !_isEmpty(this.transferReasons);
	}

	get showWriteoffReasonsDropdown(): boolean {
		return this.showWriteOffOption && EnumUtil.equals(this.formGroup.get('transferType').value, TransferType.WRITEOFF) && !_isEmpty(this.writeoffReasons);
	}

	get transferToText(): string {
		if (this.removeTransferToInsuranceOption && !this.removeTransferToPatientOption) {
			return 'Transfer To Patient';
		} else if (this.removeTransferToPatientOption && !this.removeTransferToInsuranceOption) {
			return 'Transfer To Insurance';
		}
		return 'Transfer To';
	}

	get showPayerInvoiceOptions() {
		return EnumUtil.equals(this.formGroup.get('payerType').value, PayerType.PATIENT);
	}

	get showExistingInvoiceOption() {
		return !_isEmpty(this.transferToInvoiceList);
	}

	get showExistingInvoiceDropdown() {
		return this.formGroup.get('transferToNewOrExistingInvoice').value === this.TRANSFER_TO_EXISTING_INVOICE_LABEL
			&& this.showExistingInvoiceOption;
	}

	constructor(
		private ref: DynamicModalRef,
		private modalConfig: ModalConfig,
		private accountingService: AccountingService,
		private patientService: PatientService,
		public formBuilder: GandalfFormBuilder,
		private securityManager: SecurityManagerService,
		private currencyPipe: CurrencyPipe,
		private invoiceService: InvoiceService,
		private gridUtilService: GridUtilService,
	) {
	}

	ngOnInit() {
		this.invoiceId = this.modalConfig.data.invoiceId;
		this.showWriteOffOption = this.securityManager.hasPermission(SECURITY_CONSTANTS.RESOURCE_ACCOUNTING_WRITEOFF);
		this.getData();
		this.buildRequest();
		this.buildForm();
	}

	ngOnDestroy() {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	closeModal() {
		this.ref.close(this.modal);
	}

	getData() {
		combineLatest([
			this.accountingService.findActiveTransferReasonsForDropdown(),
			this.accountingService.findActiveWriteoffReasonsForDropdown(),
			this.accountingService.getInvoiceById(this.invoiceId),
			this.accountingService.findInvoiceDetailsItemsByInvoiceId(this.invoiceId),
		]).subscribe(([transferReasons, writeoffReasons, invoice, invoiceItemDetails]) => {
			this.handleFetchedData(transferReasons, writeoffReasons, invoice, invoiceItemDetails);
			this.getPatientData();
		});
	}

	/**
	 * Receives data returned from service:
	 * - sets class variables with received data
	 * - filters list of items that can be transfered
	 * - sets invoiceItems with mapped list of items
	 */
	handleFetchedData(transferReasons: OptionItem[], writeoffReasons: OptionItem[], invoice: InvoiceResponse, invoiceItemDetails: FormattedInvoiceDetailsItem[]) {
		this.isSearching = false;
		this.transferReasons = transferReasons;
		this.writeoffReasons = writeoffReasons;
		this.invoice = invoice;
		const transferableItems = _filter(this.invoice.items, item => this.canTransfer(item));
		this.prepareFormAndRequestWithItems(transferableItems);
		this.invoiceItems = _map(transferableItems, (item) => this.updateItem({
			invoiceItem: item,
			invoiceItemDetails: _find(invoiceItemDetails, {invoiceItemId: item.id}),
			subtotal: Number(InvoiceItemPricingUtil.calculateSubTotal(item.unitPrice, item.quantity)),
			totalDiscount: 0,
			tax: 0,
			balance: 0,
			extPrice: 0,
			adjustmentTotal: 0,
			transferAmount: 0,
		}, 0));
		this.updateTotals();
	}

	/**
	 * Determines if an invoice item can be transferred
	 */
	canTransfer(item: InvoiceItemResponse) {
		// Item must not be Discounts, Credits, Refunds, Write-offs, Transfer-in or Removed
		return ((item.type === InvoiceItemType.PRODUCT
			|| item.type === InvoiceItemType.SERVICE
			|| item.type === InvoiceItemType.AD_HOC
			|| item.type === InvoiceItemType.FINANCE_CHARGE)
			&& item.status === InvoiceItemStatus.ACTIVE
			&& (!item.split && !item.wasSplit));
	}

	transferTypeText(): string {
		if (this.canTransferAndWriteoff()) {
			return 'Transfer Type';
		} else if (!this.showWriteOffOption) {
			return 'Transfer';
		}
		return 'Write-off';
	}

	/**
	 * Builds form and necessary validations
	 */
	prepareFormAndRequestWithItems(items: InvoiceItemResponse[]) {
		this.request.transferItems = [];
		items.forEach((item, index) => {
			const transferItemsFormArray = this.formGroup.get('transferItems') as GandalfFormArray;
			const addTransferItemRequest = new TransferItemRequest();
			addTransferItemRequest.transferAmount = 0;
			addTransferItemRequest.invoiceItemId = item.id;
			transferItemsFormArray.pushRequestItem(addTransferItemRequest);
			const formArrayGroup = transferItemsFormArray.get(index.toString());
			formArrayGroup.get('transferAmount').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((_value) => {
				this.transferAmountChange(addTransferItemRequest.invoiceItemId);
			});
			this.request.transferItems.push(addTransferItemRequest);
		});
	}

	/**
	 * Called when a transfer amount is changes:
	 * - updated class variables
	 * - updates grid with new values
	 * - refreshes grid
	 */
	transferAmountChange(invoiceItemId: number) {
		const index = this.getItemIndexById(invoiceItemId);
		const item = this.invoiceItems[index];
		const transferAmount = this.getInvoiceItemValue('transferAmount', invoiceItemId);
		const updatedItem = this.updateItem(item, transferAmount);
		if (!InvoiceItemPricingUtil.checkItemEquality(updatedItem.transferAmount, item.transferAmount)) {
			this.invoiceItems[index] = updatedItem;
			this.updateTotals();
		}
	}

	updateTotals() {
		const totalAmountTransferred = Number(GridUtil.sumCurrencyItems(this.invoiceItems, 'transferAmount'));
		this.totalCurrentBalance = this.currencyPipe.transform(this.invoice.balance);
		this.totalAmountTransferred = this.currencyPipe.transform(totalAmountTransferred);
		this.totalBalanceAfterTransfer = this.currencyPipe.transform(
			Number(this.getBalanceAfterTransfer()),
		);

		this.transferAmountSum = this.getSum('transferAmount');
		this.adjustmentTotalSum = this.getSum('adjustmentTotal');
		this.paymentTotalAmountSum = this.getSum('paymentTotalAmount');
		this.balanceSum = this.getSum('balance');

		this.buildAggregateData();
	}

	getSum(fieldName: string) {
		return Number(GridUtil.sumCurrencyItems(this.invoiceItems, fieldName));
	}

	private getBalanceAfterTransfer() {
		const totalAmountTransferred = Number(GridUtil.sumCurrencyItems(this.invoiceItems, 'transferAmount'));
		return GridUtil.roundCurrency(this.invoice.balance).minus(GridUtil.roundCurrency(totalAmountTransferred));
	}

	/**
	 * updates transfer items with specified transfer amount
	 */
	updateItem(transferItem: TransferItem, transferAmount: number) {
		const newItem = {...transferItem};
		newItem.transferAmount = transferAmount;
		newItem.tax = newItem.invoiceItem.taxAmount;
		newItem.extPrice = newItem.invoiceItem.extendedPrice;
		newItem.adjustmentTotal = Number(InvoiceItemPricingUtil.combineDiscounts(newItem.invoiceItem.adjustmentTotal, newItem.transferAmount));

		const balance = GridUtil.roundCurrency(newItem.invoiceItem.balance);
		const transferAmountBig = GridUtil.roundCurrency(transferAmount);
		newItem.balance = Number(balance.minus(transferAmountBig));
		return newItem;
	}

	getInvoiceItemControl(field: string, id: number) {
		const index = this.getItemIndexById(id);
		return index >= 0 ? this.getInvoiceItemControlByIndex(index, field) : null;
	}

	private getInvoiceItemControlByIndex(index, field: string) {
		return this.formGroup.get('transferItems').get(index.toString()).get(field);
	}

	private getItemIndexById(id) {
		return _findIndex(this.invoiceItems, (item) => item.invoiceItem.id === id);
	}

	getInvoiceItemValue(field: string, id): number {
		return this.getInvoiceItemControl(field, id).value;
	}

	getPatientData() {
		combineLatest([
			this.patientService.getPatientNameById(this.invoice.patientId),
			this.accountingService.findActiveInsurancesByPatientId(this.invoice.patientId),
			this.accountingService.findPatientPendingInvoicesForDropdown(this.invoice.patientId),
		]).subscribe(([patientName, personInsurance, patientPendingInvoices]) => {
			this.patientName = patientName;
			this.personInsuranceList = personInsurance;
			this.transferToInvoiceList = patientPendingInvoices;
			this.handleInsuranceOptionsResults();
			this.handlePendingPatientInvoices();
			this.initFormValues();
		});
	}

	buildRequest() {
		this.request = new TransferInvoiceItemsRequest();
		this.request.invoiceId = this.invoiceId;
	}

	buildForm() {
		this.formGroup = this.formBuilder.group(this.request, {
			validators: [
				assertTrue(
					() => this.formGroup && this.invoice && this.getBalanceAfterTransfer().gte(0),
					[],
					'Balance',
					'Transfer amount cannot be greater than the Balance',
				),
				conditionallyRequiredValidator(
					'transferReferenceId',
					() => this.formGroup && this.formGroup.get('transferReferenceId').enabled,
					'transferReferenceIdRequired',
					'Transfer reason is required',
				),
				conditionallyRequiredValidator(
					'writeoffReferenceId',
					() => this.formGroup && this.formGroup.get('writeoffReferenceId').enabled,
					'writeoffReferenceIdRequired',
					'Write-off reason is required',
				),
				conditionallyRequiredValidator(
					'personInsuranceId',
					() => this.formGroup && this.formGroup.get('personInsuranceId').enabled,
					'personInsuranceIdRequired',
					'Insurance Policy is required',
				),
			],
		});
		this.formGroup.addControl('transferToNewOrExistingInvoice', new UntypedFormControl());
		this.handleFormState();
	}

	/**
	 * if patient does not have insurance options payerType 'insurance' options is removed from the view
	 */
	handleInsuranceOptionsResults() {
		// need to filter out the insurance options that is applied to the current invoice as the item couldn't be transferred to that insurance
		this.personInsuranceList = _filter(this.personInsuranceList, insurance => insurance.value !== this.invoice.personInsuranceId);
		if (this.personInsuranceList.length === 0) {
			this.removeTransferToInsuranceOption = true;
		} else {
			this.updatePersonInsuranceDropdown();
		}
	}

	updatePersonInsuranceDropdown() {
		if (EnumUtil.equals(this.formGroup.get('payerType').value, PayerType.INSURANCE)) {
			if (this.personInsuranceList?.length === 1) {
				this.formGroup.get('personInsuranceId').setValue(this.personInsuranceList[0].id);
			}
		}
	}

	/**
	 * if patient does not have existing pending patient invoices transferTo 'existing' option is removed from view.
	 */
	handlePendingPatientInvoices() {
		if (this.transferToInvoiceList.length === 0) {
			this.removeTransferToExistingInvoiceOption = true;
		}
	}

	/**
	 * Initialized form values and sets class members to be used in the view
	 */
	initFormValues() {
		// if invoice type is patient payerType should default to Insurance and the 'patient' option is removed from the view
		this.removeTransferToPatientOption = this.invoice.payerType === PayerType.PATIENT;
		if (this.removeTransferToPatientOption) {
			this.formGroup.get('payerType').setValue(this.payerType.INSURANCE);
		} else {
			this.formGroup.get('payerType').setValue(this.payerType.PATIENT);
		}

		// if Include All Items for Transfer Items practice preference is enabled then include all items in transfer should be checked by default
		if (this.securityManager.preferenceValueIsOn(PreferenceName.ACCOUNTING_INVOICE_TRANSFER_ITEMS_DEFAULT_INCLUDE_ALL_ITEMS.value, 'false')) {
			this.formGroup.get('includeAllItems').setValue(true);
		} else {
			this.formGroup.get('includeAllItems').setValue(false);
		}

		// if patient does not have existing pending patient invoices patient transfer to 'new' invoice is selected by default
		// otherwise 'new' invoice is selected by default and the first invoice is the list is selected
		if (this.removeTransferToExistingInvoiceOption) {
			this.formGroup.get('transferToNewOrExistingInvoice').setValue(this.TRANSFER_TO_NEW_INVOICE_LABEL);
		} else {
			this.formGroup.get('transferToNewOrExistingInvoice').setValue(this.TRANSFER_TO_EXISTING_INVOICE_LABEL);
			this.formGroup.get('transferToExistingInvoiceId').setValue(this.transferToInvoiceList[0].value);
		}

		// Transfer To section should not appear if an invoice item is a Finance Charge OR
		// 		- invoice is a patient invoices (removes transfer to Patient option) AND
		// 		- patient doesn't have insurance options available (removes transfer to insurance options)
		// transferType should default to writeoff
		this.hasFinanceCharge = !_isNil(_find(this.invoice.items, item => item.status === InvoiceItemStatus.ACTIVE && item.type === InvoiceItemType.FINANCE_CHARGE));
		this.setHideTransferToSection();
		if (this.hideTransferToSection) {
			if (this.showWriteOffOption) {
				this.formGroup.get('transferType').setValue(this.transferType.WRITEOFF);
			}
		} else {
			this.formGroup.get('transferType').setValue(this.transferType.TRANSFER);
		}
	}

	private setHideTransferToSection() {
		this.hideTransferToSection = this.hasFinanceCharge ||
			(EnumUtil.equals(this.invoice.payerType, PayerType.PATIENT) && (_isNil(this.personInsuranceList) || this.personInsuranceList.length === 0)) ||
			(this.removeTransferToPatientOption && this.removeTransferToInsuranceOption);
	}

	/**
	 * Sets up on change events for various form fields
	 */

	/* istanbul ignore next */
	handleFormState() {
		// if 'transfer' is selected writeoff reason dropdown should be disabled
		// if 'writeoff' is selected transfer reason dropdown and person insurance dropdown should be disabled
		this.formGroup.get('transferType').valueChanges
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(val => {
				this.formGroup.get('transferReferenceId').enable();
				this.formGroup.get('writeoffReferenceId').enable();
				FormUtilsService.disabledWhen(
					this.formGroup.get('transferReferenceId'),
					EnumUtil.equals(val, this.transferType.WRITEOFF),
				);
				FormUtilsService.disabledWhen(
					this.formGroup.get('writeoffReferenceId'),
					EnumUtil.equals(val, this.transferType.TRANSFER),
				);
				FormUtilsService.disabledWhen(
					this.formGroup.get('personInsuranceId'),
					EnumUtil.equals(val, this.transferType.WRITEOFF) ||
					EnumUtil.equals(this.formGroup.get('payerType').value, this.payerType.PATIENT),
				);
			});

		// if payerType 'patient' is selected insurance dropdown should be disabled
		this.formGroup.get('payerType').valueChanges
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(val => {
				this.formGroup.get('personInsuranceId').enable();
				FormUtilsService.disabledWhen(
					this.formGroup.get('personInsuranceId'),
					val === this.payerType.PATIENT || this.formGroup.get('transferType').value === this.transferType.WRITEOFF,
				);
				FormUtilsService.disabledWhen(
					this.formGroup.get('transferToNewOrExistingInvoice'),
					EnumUtil.equals(val, this.payerType.INSURANCE),
				);
				FormUtilsService.disabledWhen(
					this.formGroup.get('transferToExistingInvoiceId'),
					EnumUtil.equals(val, this.payerType.INSURANCE),
				);
			});

		// if transfer to new invoice is selected existing invoice dropdown should be disabled
		this.formGroup.get('transferToNewOrExistingInvoice').valueChanges
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(val => {
				this.formGroup.get('transferToExistingInvoiceId').enable();
				FormUtilsService.disabledWhen(
					this.formGroup.get('transferToExistingInvoiceId'),
					val === this.TRANSFER_TO_NEW_INVOICE_LABEL,
				);
			});
	}

	/* istanbul ignore next */
	submitForm(event) {
		this.templateForm.onSubmit(event);
	}

	transferItems() {
		if (this.formGroup.invalid) {
			return;
		}

		const transferRequest: TransferInvoiceItemsRequest = this.formGroup.getRawValue();
		// if transfer to new or existing is 'new' don't send the existing invoice id
		transferRequest.transferToExistingInvoiceId = transferRequest['transferToNewOrExistingInvoice'] !== this.TRANSFER_TO_NEW_INVOICE_LABEL
			? transferRequest.transferToExistingInvoiceId
			: null;
		this.accountingService.transferInvoiceItems(transferRequest).subscribe((data) => {
			this.refreshInvoices(data);
			this.closeModal();
		});
	}

	refreshInvoices(invoiceResponses: InvoiceResponse[]) {
		invoiceResponses.forEach(invoiceResponse => {
			this.invoiceService.refreshInvoice(invoiceResponse.id);
		});
	}

	canTransferOrWriteoff() {
		return !this.hideTransferToSection || this.showWriteOffOption;
	}

	canTransferAndWriteoff() {
		return !this.hideTransferToSection && this.showWriteOffOption;
	}

	onTabKeydown(event: KeyboardEvent, isTransferToExistingDropdown: boolean, isAllItemsTransferCheckbox: boolean) {
		const shouldSkipEventFromCheckbox = this.showTransferReasonsDropdown && isAllItemsTransferCheckbox;
		const shouldSkipEventExistingInvoice = this.showPayerInvoiceOptions && this.showExistingInvoiceDropdown && !isTransferToExistingDropdown;
		if (shouldSkipEventFromCheckbox || shouldSkipEventExistingInvoice) {
			return;
		}

		event.preventDefault();
		this.agGrid.api.ensureIndexVisible(0);

		// sets focus into the first row payment column
		const transferColumn = this.agGrid.columnApi.getColumn('transferAmount');
		this.agGrid.api.setFocusedCell(0, transferColumn);
	}


	buildAggregateData() {
		const aggregateRow = [{
			isAggregateRow: true,
			transferAmount: this.transferAmountSum,
			adjustmentTotal: this.adjustmentTotalSum,
			paymentTotalAmount: this.paymentTotalAmountSum,
			balance: this.balanceSum,
		}];
		GridUtil.setAgGridAggregateRow(this.agGrid, aggregateRow);
	}

	onGridReady(params: GridReadyEvent) {
		this.transferItemGridApi = params.api;
		this.transferItemGridColumnApi = params.columnApi;
		this.buildGridColumns();

		this.agGrid.api.setColumnDefs(this.transferItemColumns);
		if (!_isNil(this.invoiceItems)) {
			this.buildAggregateData();
		}
	}

	buildGridColumns() {
		this.transferItemColumns = [
			GridUtil.buildColumn('Code', 'invoiceItem.code', {
				width: 85,
				sortable: false,
				suppressNavigable: true,
				tooltipField: 'invoiceItem.code',
			}),
			GridUtil.buildColumn('Description', 'invoiceItem.description', {
				minWidth: 120,
				sortable: false,
				suppressNavigable: true,
				flex: 1,
				tooltipField: 'invoiceItem.description',
			}),
			GridUtil.buildNumericColumn('Qty', 'invoiceItem.quantity', {
				width: 45,
				sortable: false,
				suppressNavigable: true,
			}),
			this.gridUtilService.buildCurrencyColumn('Unit Price', 'invoiceItem.unitPrice', {
				width: 80,
				sortable: false,
				suppressNavigable: true,
			}),
			this.gridUtilService.buildCurrencyColumn('Sub-Total', 'subtotal', {
				width: 80,
				sortable: false,
				suppressNavigable: true,
			}),
			this.gridUtilService.buildCurrencyColumn('Discounts', 'invoiceItem.discountTotal', {
				width: 80,
				sortable: false,
				suppressNavigable: true,
				tooltipField: 'totalDiscount',
				tooltipComponent: TooltipCellRendererComponent,
				tooltipComponentParams: {
					ngTemplate: this.totalDiscountTooltip,
				},
			}),
			this.gridUtilService.buildCurrencyColumn('Tax', 'tax', {
				width: 70,
				sortable: false,
				suppressNavigable: true,
			}),
			this.gridUtilService.buildCurrencyColumn('Ext. Price', 'extPrice', {
				width: 80,
				sortable: false,
				suppressNavigable: true,
			}),
			GridUtil.buildTemplateColumn('Transfer', 'transferAmount', this.transferColumn, {
				width: 95,
				sortable: false,
				valueGetter: params => this.getTransferAmountFormControl(params.node)?.value,
			}),
			GridUtil.buildTemplateColumn('Adjustments', 'adjustmentTotal', this.adjustmentsColumn, {
				width: 100,
				sortable: false,
				suppressNavigable: true,
				tooltipField: 'adjustmentTotal',
				tooltipComponent: TooltipCellRendererComponent,
				tooltipComponentParams: {
					ngTemplate: this.adjustmentTooltip,
				},
				type: 'numericColumn',
			}),
			GridUtil.buildTemplateColumn('Paid', 'invoiceItem.amountPaid', this.paymentTotalColumn, {
				width: 80,
				type: 'numericColumn',
				suppressNavigable: true,
				sortable: false,
			}),
			GridUtil.buildTemplateColumn('Balance', 'balance', this.balanceColumn, {
				width: 95,
				type: 'numericColumn',
				suppressNavigable: true,
				sortable: false,
			}),
		];
	}

	@LogMethodTime('onCellFocus')
	onCellFocus(event: CellFocusedEvent) {
		const column = event?.column as Column;
		if (!column?.getColId() || this.cancelFocusEvent) {
			this.cancelFocusEvent = false;
			return;
		}

		const focusedCell = {
			rowIndex: event.rowIndex,
			colId: column.getColId(),
		};

		if (
			focusedCell.rowIndex === this.lastFocusedCell?.rowIndex &&
			focusedCell.colId === this.lastFocusedCell?.colId
		) {
			if (event.rowPinned !== 'bottom') {
				this.lastFocusedCell = {
					rowIndex: event.rowIndex,
					colId: column.getColId(),
				};
				this.agGrid.api.stopEditing();
			} else {
				this.lastFocusedCell = {
					rowIndex: null,
					colId: null,
				};
			}

			return;
		}

		const rowNode = this.agGrid.api.getDisplayedRowAtIndex(event.rowIndex);

		if (column.isSuppressNavigable(rowNode)) {
			this.lastFocusedCell = {
				rowIndex: event.rowIndex,
				colId: column.getColId(),
			};
			this.transferItemGridApi.tabToNextCell();
			return;
		}

		// pinned rows are aggregates and shouldn't be editable
		const isRowPinned = this.isAggregateRow(rowNode.data);

		const isEditable = this.isCellEditable(column.getColId(), rowNode);

		this.lastFocusedCell = {
			rowIndex: event.rowIndex,
			colId: column.getColId(),
		};

		if (!isRowPinned && isEditable) {
			this.agGrid.api.ensureIndexVisible(event.rowIndex);
			this.focusedRowId = rowNode.data.invoiceItem.id;
		} else {
			this.transferItemGridApi.tabToNextCell();
		}
	}

	onInputBlur() {
		this.focusedRowId = null;
	}

	onCellClick(event: CellMouseDownEvent) {
		const rowNode = this.agGrid.api.getDisplayedRowAtIndex(event.rowIndex);
		const isEditable = this.isCellEditable(event.column.getColId(), rowNode);

		this.cancelFocusEvent = !isEditable;
	}

	getItemByItem(id) {
		const itemIndex = this.getItemIndexById(id);
		return itemIndex >= 0 ? this.invoiceItems[this.getItemIndexById(id)] : null;
	}

	isAggregateRow(data) {
		return !!data.isAggregateRow;
	}

	getTransferAmountFormControl = (rowNode: IRowNode) => this.getInvoiceItemControl('transferAmount', rowNode?.data?.invoiceItem?.id);

	@LogMethodTime('isCellEditable')
	isCellEditable(colKey: string, rowNode: IRowNode) {
		if (colKey === 'transferAmount') {
			return this.isTransferCellEditable(rowNode);
		}
	}

	isTransferCellEditable = (rowNode: IRowNode) => !this.isAggregateRow(rowNode?.data) ? this.getTransferAmountFormControl(rowNode)?.enabled : false;

	filterAdjustments(itemAdjustments: InvoiceItemAdjustmentResponse[], isDiscount: boolean): InvoiceItemAdjustmentResponse[] {
		return _filter(itemAdjustments, adjustment =>
			isDiscount === EnumUtil.equals(adjustment.type, InvoiceItemAdjustmentType.DISCOUNT)
			&& EnumUtil.equals(adjustment.status, InvoiceItemAdjustmentStatus.ACTIVE));
	}

	sortBy<T>(collection: Array<T>, properties: any): Array<T> {
		return SortingService.sortBy(collection, properties);
	}

	isCellSelected(id: number, field: string) {
		if (!_isNil(id) && !_isNil(this.focusedRowId) && this.focusedColumnKey) {
			return this.focusedRowId === id && this.focusedColumnKey === field;
		}

		return false;
	}

	shouldShowTooltip(data: any, isDiscount: boolean) {
		return (this.filterAdjustments(data?.invoiceItemDetails?.itemAdjustments, isDiscount)?.length || 0) !== 0;
	}

}
